<template>
  <div>
    <details-component />
  </div>
</template>

<script>
import DetailsComponent from '@/components/catalog/Details/Details.vue'

export default {
  components: { DetailsComponent },
  name: 'Detail',
}
</script>
