<template>
  <div>
    <v-dialog
      v-model="isActive"
      persistent
      transition="dialog-transition"
      width="500"
      height="180"
    >
      <span class="d-none">{{ change }}</span>

      <v-card class="rounded-lg">
        <v-card-title>
          <v-row>
            <v-col cols="11" class="px-0">
              <h6 class="text-subtitle-1 font-weight-bold text-center">
                <span class="font-work-sans ml-9"
                  >{{ edit ? 'Modificar ' : 'Crear ' }}Detalle</span
                >
              </h6>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-btn color="primary" icon @click="$emit('dismiss')"
                ><v-icon small>fa-times</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6"
                ><label for="" class="ml-4 black--text font-weight-medium"
                  >Nombre</label
                >
                <v-text-field
                  placeholder="Nombre"
                  color="primary"
                  rounded
                  prepend-inner-icon="fa-file-alt"
                  :rules="[rules.required, rules.alfanumeric]"
                  required
                  outlined
                  class="mt-2 mb-n3"
                  v-model="detailData.name"
                ></v-text-field>

                <label for="" class="ml-4 black--text font-weight-medium"
                  >Examen</label
                >
                <v-select
                  ref="info"
                  placeholder="Examen"
                  :rules="[rules.required]"
                  v-model="detailData.testId"
                  prepend-inner-icon="fa-vial"
                  :items="tests"
                  outlined
                  rounded
                  class="mt-2 mb-n3"
                ></v-select>

                <label for="" class="ml-4 black--text font-weight-medium"
                  >Medición</label
                >
                <v-text-field
                  placeholder="Medición"
                  color="primary"
                  rounded
                  :rules="[rules.required, rules.alfanumeric]"
                  prepend-inner-icon="fa-file-alt"
                  required
                  outlined
                  class="mt-2 mb-n3 font-quicksand"
                  v-model="detailData.measurement"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <label for="" class="ml-4 black--text font-weight-medium"
                  >Formato</label
                >
                <v-text-field
                  placeholder="Formato"
                  color="primary"
                  rounded
                  :rules="[rules.required, rules.integer]"
                  prepend-inner-icon="fa-file-alt"
                  required
                  outlined
                  class="mt-2 mb-n3 font-quicksand"
                  v-model="detailData.idFor"
                ></v-text-field>

                <label for="" class="ml-4 black--text font-weight-medium"
                  >IdObj</label
                >
                <v-text-field
                  placeholder="IdObj"
                  color="primary"
                  rounded
                  :rules="[rules.required, rules.integer]"
                  prepend-inner-icon="fa-file-alt"
                  required
                  outlined
                  class="mt-2 mb-n3 font-quicksand"
                  v-model="detailData.idObj"
                ></v-text-field>

                <label for="" class="ml-4 black--text font-weight-medium"
                  >TipObj</label
                >
                <v-text-field
                  placeholder="TipObj"
                  color="primary"
                  rounded
                  :rules="[rules.required, rules.alfanumeric, rules.counter]"
                  prepend-inner-icon="fa-file-alt"
                  required
                  outlined
                  class="mt-2 mb-n3 font-quicksand"
                  v-model="detailData.tipObj"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n4">
          <v-btn
            color="primary"
            outlined
            class="rounded-lg px-4"
            @click="
              () => {
                $refs.form.reset()
                $emit('dismiss')
              }
            "
          >
            <v-icon small>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="rounded-lg elevation-0 px-4"
            @click="submit"
            :loading="loading"
          >
            <v-icon small>fa-check</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="activeAlerts()"
    />
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
import DialogEdit from '@/components/DialogEdit.vue'
const DetailRepository = Repository.get('details')
const TestRepository = Repository.get('tests')

export default {
  components: { DialogEdit },
  name: 'FormDialogDetail',
  props: {
    detail: {
      type: Object,
      default: {
        name: '',
        measurement: '',
        testId: '',
        idFor: '',
        idObj: '',
        tipObj: '',
      },
    },
    dialog: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    valid: false,
    loading: false,
    detailData: {
      name: '',
      measurement: '',
      testId: '',
      idFor: '',
      idObj: '',
      tipObj: '',
    },
    dialogEdit: false,
    item: {},
    tests: [],
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
      integer: (value) => {
        const pattern = /[^0-9]/
        return !pattern.test(value) || 'Campo inválido.'
      },
      number: (value) => {
        const pattern = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/
        return pattern.test(value) || 'Campo inválido.'
      },
      counter: (value) => value?.length === 1 || 'Campo inválido.',
    },
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
        if (this.detail) this.detailData = Object.assign({}, this.detail)
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted: async function () {
    await this.getTests()
  },
  methods: {
    getTests: async function () {
      try {
        const res = (await TestRepository.get()).data.data
        this.tests = Array.from(res.items, (item) => {
          return {
            text: item.name || item.id,
            value: item.id,
          }
        })
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },

    submit: function () {
      this.$refs.form.validate()

      setTimeout(() => {
        if (this.valid) {
          if (!this.edit) this.activeAlerts()
          else {
            this.item = {
              type: 'indicación',
              id: this.detail.id,
              name: this.detail.name,
            }
            this.dialogEdit = true
          }
        }
      })
    },

    activeAlerts: async function () {
      this.dialogEdit = false

      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.edit
          ? 'Detalle actualizado con éxito'
          : 'Detalle registrado con éxito',
      }

      this.loading = true

      try {
        this.edit
          ? await DetailRepository.update(this.detailData, this.detailData.id)
          : await DetailRepository.create(this.detailData)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$refs.form.reset()
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },
  },
}
</script>
